import { useGetList, useRefresh, useGetOne, useResetStore, CheckForApplicationUpdate, AppBar} from 'react-admin';
import {AppLocationContext, ContainerLayout, Header } from '@react-admin/ra-navigation';
import { Breadcrumb } from '@react-admin/ra-enterprise';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Autocomplete, Select, MenuItem } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import {useTenantId} from "../data/useTenantId";
import {CustomUserMenu, Menu} from "./Menu";
import {useIsAdmin} from "../auth/utils";
import App from "../App";
import {appTheme} from "../utils/theme";
import {BreezeBreadcrumb} from "./Breadcrumb";
export const TenantContext = React.createContext<{
    tenant: {
        next_case_ref_serial_number: number,
        id: string,
        cc_email: string,
        auto_annuity: boolean,
        currency_code: string
        annuity_override_our_fee: string
        annuity_override_our_fee_currency: string
        feature_documents: boolean,
        feature_trademarks: boolean,
        feature_designs: boolean,
        feature_other_cases: boolean,
        feature_actions: boolean,
        feature_tasks: boolean,
        feature_charging: boolean,
        is_agent: boolean,
        pct_fee?: number
        ep_fee?: number
        hourly_fee?: number
    }
    refetch: () => void
} | null>(null);

export const noRefetch = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
}

export const useTenantContext = () => useContext(TenantContext);
export const AppLayout = React.memo((props: any) => {
    const tokenSelectedTenant = useTenantId();
    const isAdmin = useIsAdmin();

    const { data: tenant, isLoading, error, refetch } = useGetOne(
        "tenants",
        {
            id: tokenSelectedTenant,
        },
        noRefetch
    );

    if (isLoading || !tenant) {
        return null;
    }

    return (
        <TenantContext.Provider value={{
            tenant,
            refetch
        }}>
            {/*<AppLocationContext hasDashboard>*/}
                <ContainerLayout
                    {...props}
                    theme={appTheme}
                    maxWidth={1600}
                    // error={ErrorPage}
                    appBar={<Header title={<ClientSelector/>} />}
                    menu={<Menu/>}
                    userMenu={<CustomUserMenu/>}>
                    {/*<BreezeBreadcrumb />*/}
                    {props.children}
                    <CheckForApplicationUpdate interval={isAdmin ? 60000 : 3600000} />
                </ContainerLayout>
            {/*</AppLocationContext>*/}
        </TenantContext.Provider>
    );
});

const changeClientRequest = (body: object) => fetch(
    `${process.env.REACT_APP_FUNCTIONS_URL}/changeClient` as string,
    {
        method: "POST",
        body: JSON.stringify(body)
    });

const ClientSelector: FunctionComponent<any> = React.memo((props) => {
    //TODO: teste å flytte denne logikken opp i funksjonen over
    const { data, isLoading, error } = useGetList('tenants', { pagination: { perPage: 45, page: 1}}, noRefetch);
    const refresh = useRefresh();
    const reset = useResetStore();
    const auth0Data = useAuth0<any>();
    const tokenSelectedTenant = useTenantId();

    const [selectedTenant, setSelectedTenant] = useState<string>(tokenSelectedTenant || '');


    if (isLoading || !data || data.length < 1 || !selectedTenant) return null;

    if (data.length === 1) return <>{data[0].name}</>


    const handleChange = (event: any) => {
        console.log("Change client: Fire action to start changing the token for the client");
        console.log(auth0Data);
        // debugger;
        event?.preventDefault();
        const tenantId = event?.target?.value
        if (tenantId) {
            // Make call to serverless function to change the selected client
            changeClientRequest({
                selected_tenant: tenantId,
                auth0UserId: auth0Data?.user?.sub
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    reset();
                    // Frontend asks for new token from Auth0
                    auth0Data.getAccessTokenSilently({ cacheMode: "off" }).then((res) => {
                        // Frontend refetches data (and possibly re-renders)
                        console.log(res, "refreshing...");
                        // refresh();
                        window.location.reload();
                    });
                }).catch(console.error);
        }
        setSelectedTenant(tenantId);
    }
    return (
        <Select
            onChange={handleChange}
            // Need this to prevent some strage handling of the event by the app bar or something
            // Without stopping the event, the page would always navigate to the root page on close, causing strange errors
            onClose={(e) => e?.preventDefault()}
            value={selectedTenant}
            label={"Client"}
            size={"small"}
            color={"secondary"}
            sx={{ width: 250, color: "white" }}>
            {
                data?.map((tenant) => <MenuItem value={tenant.id}>{tenant.name}</MenuItem>)
            }
        </Select>
    )
});
