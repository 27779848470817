import {countries} from "../../utils/countries";
import {dateFormat} from "./Annuities";
// @ts-ignore
import jsonExport from 'jsonexport/dist';
import moment from "moment";
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs';
import {ColInfo, WorkSheet } from "xlsx";
export const annuitiesExporter = (isAdmin: boolean) => (annuities: any[]) => {
    const annuitiesForExport = annuities
        .map((annuity) => {
            const is_grace = annuity.due_date !== annuity.effective_due_date;
            const price = annuity.currency_code === "USD" ?
                (is_grace ? annuity.annuity_price?.usd_grace_price : annuity.annuity_price?.usd_price) :
                (is_grace ? annuity.annuity_price?.nok_grace_price : annuity.annuity_price?.nok_price);

            const client_price = price + (annuity.annuity_tenant_fee || 0);

            const priceObj = {
                price,
                client_price,
                currency: annuity.currency_code === "USD" ? "USD" : "NOK",
            };

            return {
                ...annuity,
                country_code: annuity.country_code?.toUpperCase(),
                client_ref: annuity.tenant_case_ref,
                nok_our_fee: annuity.annuity_price?.nok_our_fee,
                usd_our_fee: annuity.annuity_price?.usd_our_fee,
                nok_official_fee: annuity.annuity_price?.nok_official_fee,
                usd_official_fee: annuity.annuity_price?.usd_official_fee,
                nok_agent_fee: annuity.annuity_price?.nok_agent_fee,
                usd_agent_fee: annuity.annuity_price?.usd_agent_fee,
                nok_price: annuity.annuity_price?.nok_price,
                usd_price: annuity.annuity_price?.usd_price,
                is_grace: is_grace ? "Yes" : "No",
                ...priceObj,
                client_price_usd: annuity.annuity_price?.usd_price ? annuity.annuity_price?.usd_price + 500 : null,
                type: annuity.case_ref?.startsWith("P") ? "Patent" : annuity.case_ref?.startsWith("TM") ? "Trademark" : annuity.case_ref?.startsWith("D") ? "Design" : "Unknown"
            };
        })
        .map((annuity) => {
            const annuityForExport: { [key: string]: any} = {};
            Object.keys(annuity)
                .filter((attribute) => exportFields.includes(attribute))
                .filter((attribute) => isAdmin || isAllowedUserField(attribute))
                .forEach((atttibute) => annuityForExport[atttibute] = annuity[atttibute]);

            annuityForExport.country = countries[annuity.country_code?.toLowerCase()];
            return annuityForExport;
        });

    // const worksheet = XLSX.utils.json_to_sheet(annuitiesForExport);
    const annuitiesArrayOfArray = annuitiesForExport.map((annuity) => (isAdmin ? exportFields : allowedUserFields).map((field) => annuity[field]));
    const headerRow = isAdmin ? exportFields : allowedUserFields;
    const worksheet = XLSX.utils.aoa_to_sheet([
        headerRow.map(prettifyHeader),
        ...annuitiesArrayOfArray,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Annuities");

    /* fix headers */
    // XLSX.utils.sheet_add_aoa(worksheet, [
    //     isAdmin ? exportFields.map(prettifyHeader) : allowedUserFields.map(prettifyHeader),
    //     ...annuitiesForExport.map((annuity) => (isAdmin ? exportFields : allowedUserFields).map((field) => annuity[field])),
    // ]);

    // const max_width = annuitiesForExport.reduce((w, r) => Math.max(w, r.applicant?.length), 10);
    // worksheet["!cols"] = annuitiesArrayOfArray.map((array: Array<any>) => array.reduce((w, r) => Math.max(w, r.applicant?.length), 10));
    worksheet["!cols"] = headerRow.map((header: any) => ({ wch: annuitiesForExport.reduce((w, r) => Math.max(w, r?.[header]?.length || 0), prettifyHeader(header).length) }) );

    XLSX.writeFile(workbook, `annuities-${moment().format(dateFormat)}.xlsx`, { compression: true });

    // jsonExport(annuitiesForExport, {
    //     headers: isAdmin ? exportFields : allowedUserFields, // order fields in the export
    //     mapHeaders: (header: string) => capitalizeFirstLetter(header.replaceAll("_", ' '))
    // }, (err:any, csv:any) => {
    //
    // });
};

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
const exportFields = [
    "country",
    "country_code",
    "case_ref",
    "client_ref",
    "applicant",
    "application_number",
    "registration_number",
    "type",
    "action_name",
    "due_date",
    "with_fine_due_date",
    "effective_due_date",
    "is_grace",
    "case_entity_size",
    // "our_fee",
    // "our_fee_currency",
    // "nok_our_fee",
    // "usd_our_fee",
    // "official_fee",
    // "official_fee_currency",
    // "nok_official_fee",
    // "usd_official_fee",
    // "agent_fee",
    // "agent_fee_currency",
    // "nok_agent_fee",
    // "usd_agent_fee",
    // "nok_price",
    // "usd_price",
    // "client_price_usd",
    "price",
    "client_price",
    "currency",
];

const allowedUserFields = [
    "country",
    "country_code",
    "case_ref",
    "client_ref",
    "applicant",
    "application_number",
    "registration_number",
    "type",
    "action_name",
    "effective_due_date",
    "is_grace",
    "case_entity_size",
    "price",
    "client_price",
    "currency",
    "usd_price",
    "client_price_usd",
];

const isAllowedUserField = (field: string) => {
    return allowedUserFields.includes(field);
}

const prettifyHeader = (header: string) => {
    if (header === "effective_due_date") return "Due date";
    if (header === "case_entity_size") return "Entity size";
    if (header === "is_grace") return "Grace";
    if (header === "usd_price") return "Breeze Price (USD)";
    if (header === "price") return "Breeze Price";
    if (header === "client_price_usd") return "Price (USD)";
    if (header === "client_price") return "Price";

    return capitalizeFirstLetter(header.replaceAll("_", ' '))
};
