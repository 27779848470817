import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    BooleanInput
} from 'react-admin';
import {entitySizeOptions} from "../common/common-utils";
import {currencyOptions} from "./TenantCreate";


export const TenantEdit = (props: any) => {
    return (
        <Edit title="Edit a client" {...props} >
            <SimpleForm>
                <TextInput source={"name"} />
                <TextInput source={"cc_email"} />
                <TextInput source={"logo_url"} />
                <NumberInput source={"admin_fee_factor"} defaultValue={0.05} />
                <NumberInput source={"next_case_ref_serial_number"} />
                <BooleanInput source={"auto_annuity"} />
                <BooleanInput source={"calculate_annuity_costs"} />
                <TextInput source={"products"} label={"Products options (comma separated)"} />
                <TextInput source={"departments"} label={"Department options (comma separated)"} />
                <SelectInput source={"annuity_canada_entity_size"} choices={entitySizeOptions} />
                <SelectInput source={"annuity_usa_entity_size"} choices={entitySizeOptions} />
                <NumberInput source={"annuity_override_our_fee"} />
                <TextInput source={"annuity_override_our_fee_currency"} />
                <NumberInput source={"annuity_tenant_fee"} label={"Annuity client fee"} helperText={"Our client's fee, used in exports so the client can send pricing data to their clients"} />
                <NumberInput source={"pct_fee"} />
                <NumberInput source={"ep_fee"} />
                <NumberInput source={"hourly_fee"} />
                <SelectInput source={"currency_code"} choices={currencyOptions} required />
                <BooleanInput source={"is_agent"} />
                <BooleanInput source={"feature_documents"} />
                <BooleanInput source={"feature_trademarks"} />
                <BooleanInput source={"feature_designs"} />
                <BooleanInput source={"feature_other_cases"} />
                <BooleanInput source={"feature_actions"} />
                <BooleanInput source={"feature_tasks"} />
                <BooleanInput source={"feature_charging"} />
            </SimpleForm>
        </Edit>
    );
};
