import {Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import Mustache from "mustache";
import { useState } from "react";
import { Button, useNotify, useDataProvider, useListContext, required, Form, useRefresh } from "react-admin";
import { countries } from "../../utils/countries";
import {
    DefaultEditorOptions,
    RichTextInput,
} from 'ra-input-rich-text';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import React from "react";
import {useTenantContext} from "../../react-admin-overrides/AppLayout";
import {useUserId} from "../../auth/utils";
import moment from "moment";
import {INSTRUCTION_DO_NOT_PAY, INSTRUCTION_PAY} from "../annuities/Annuities";
export const SendConfirmationEmailButton = () => {
    const [open, setOpen] = useState(false);
    const [emailPreview, setEmailPreview] = useState("");
    const [emailData, setEmailData] = useState<any>(null);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { selectedIds, data, onUnselectItems } = useListContext();
    const editorRef = React.useRef<any>(null);
    const { tenant } = useTenantContext()  || {};
    const userId = useUserId();
    const refresh = useRefresh();

    const selected = data.filter((d: any) => selectedIds.includes(d.id));
    const selectedCaseActionIds = selected.map((d: any) => d.case_action_id);

    const handleClick = async () => {
        // TODO: Verify that the selection does not contain items from two different tenants -> Show error if it does

        const emailTemplateId = selected.some((d: any) => d.instruction === INSTRUCTION_PAY) ?
            '50df672c-ec98-43f1-b8b4-f2c0c4c929bf' // Pay template
            :
            "0e47434a-0c67-402d-a0a6-3af50e92df34"; // Works for both pay and do not pay
        try {
            // Fetch email template and preview
            const response = await dataProvider.getOne('email_templates', {
                id: emailTemplateId // You'll need to create this template
            });

            const sendingUserResponse = await dataProvider.getOne('users', {id: userId});
            const recipientUsersResponse = await dataProvider.getMany('users', {
                ids: selected.map(row => row.instructed_by_user)
            });

            console.log(sendingUserResponse, recipientUsersResponse)
            
            const htmlTable = `
                <table border="1" cellpadding="5" style="border-collapse: collapse; border: 1px;">
                    <tbody>
                        <tr>
                            <th>Applicant</th>
                            <th>Case Ref</th>
                            <th>Client Ref</th>
                            <th>Country</th>
                            <th>App. #</th>
                            <th>Reg. #</th>
                            <th>Annuity</th>
                            <th>Due Date</th>
                            <th>Instruction</th>
                        </tr>
                        ${selected.map(row => `
                            <tr>
                                <td>${row.case_action?.case?.applicant || ''}</td>
                                <td>${row.case_action?.case?.case_ref || ''}</td>
                                <td>${row.case_action?.case?.tenant_case_ref || ''}</td>
                                <td>${countries[row.case_action?.case?.country_code] || ''}</td>
                                <td>${row.case_action?.case?.application_number || ''}</td>
                                <td>${row.case_action?.case?.registration_number || ''}</td>
                                <td>${row.case_action?.action_rule?.action_name || ''}</td>
                                <td>${row.case_action?.due_date || ''}</td>
                                <td>${row.instruction || ''}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            `;

            
            const recipientName = recipientUsersResponse.data.map((user: any) => user.name).join(', ');

            const emailPreview = Mustache.render(response.data?.body?.replaceAll("\n", "<div></div>") || '', {
                table: htmlTable,
                recipientName,
                senderName: sendingUserResponse.data?.name || "Breeze IP",
            }, {}, { escape: (text) => text });

            const emailSubject = Mustache.render(response.data?.subject || '', { recipientName });

            setEmailPreview(emailPreview);
            const recipientEmails = recipientUsersResponse.data.map((user: any) => user.email);
            const mainRecipient = recipientEmails[0];
            const otherRecipients = recipientEmails.slice(1);

            const ccEmails = Array.from(new Set(selected.map(row => row.case_action?.case?.tenant?.cc_email)));
            const recipientCcEmails = [...ccEmails, ...otherRecipients];
            const BREEZE_EMAIL = "post@breezeip.com";
            setEmailData({
                type: "INSTRUCTIONS_CONFIRMED_BY_BREEZE",
                sending_status: "INITIATED_BY_BREEZE",
                subject: emailSubject,
                body: emailPreview,
                recipient_email: mainRecipient,
                recipient_cc_emails: recipientCcEmails,
                recipient_bcc_emails: [mainRecipient, ...recipientCcEmails].includes(BREEZE_EMAIL) ? [] : [BREEZE_EMAIL],
            });
            setOpen(true);
        } catch (error) {
            notify('Error loading email preview', { type: 'error' });
        }
    };

    const handleSend = async (formData: any) => {
        console.log(emailData, formData);
        const now = moment();
        try {
            const { data: createdEmail } = await dataProvider.create('emails', {
                data: emailData
            });

            // Update all selected items with the confirmation email ID
            await dataProvider.updateMany('instructions_received', {
                ids: selectedIds,
                data: {
                    confirmation_email_id: createdEmail.id
                }
            });

            // Update all corresponding case actions with confirmation timestamp
            await dataProvider.updateMany('case_actions', {
                ids: selected.map(item => item.case_action_id),
                data: {
                    instructions_confirmed_by_payment_agent_by: userId,
                    instructions_confirmed_by_payment_agent_at: now,
                    inactivated_by: userId,
                    inactivated_at: now,
                }
            });

            notify('Email sent successfully', { type: 'success' });
            setOpen(false);
            onUnselectItems();
            refresh();
        } catch (error) {
            notify('Error sending email', { type: 'error' });
        }
    };

    return (
        <>
            <Button
                label="Send Email and Confirm Instructions"
                onClick={handleClick}
                disabled={selectedIds.length === 0}
            />
            
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl" fullWidth>
                <DialogTitle>Send confirmation email</DialogTitle>
                <DialogContent>

                    <Form onSubmit={handleSend}>
                        <Stack spacing={1} mb={2}>
                           <Box><strong>To:</strong> {emailData?.recipient_email}</Box>
                           <Box><strong>CC:</strong> {emailData?.recipient_cc_emails?.join(", ")}</Box>
                           <Box><strong>BCC:</strong> {emailData?.recipient_bcc_emails?.join(", ")}</Box>
                        </Stack>
                       <Box mb={5}><strong>Subject:</strong> {emailData?.subject}</Box>
                       <Box mb={2}><strong>Email:</strong></Box>
                        <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
                        {/*<RichTextInput*/}
                        {/*    source="body"*/}
                        {/*    defaultValue={emailPreview}*/}
                        {/*    fullWidth*/}
                        {/*    label={false}*/}
                        {/*    validate={[required()]}*/}
                        {/*    editorOptions={{*/}
                        {/*       ...MyEditorOptions,*/}
                        {/*       onCreate: ({ editor }: { editor: any }) => {*/}
                        {/*           editorRef.current = editor;*/}
                        {/*       },*/}
                        {/*   }}*/}
                        {/*/>*/}

                        <Button
                            label="Send Confirmation Email"
                            type={"submit"}
                            sx={{ mt: 2 }}
                        />
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const MyEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        // @ts-ignore
        ...DefaultEditorOptions.extensions,
        Table,
        TableCell,
        TableHeader,
        TableRow
    ],
};
